<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import miniCart from '../apps/mini_cart'

export default Vue.component('order-updater', {
  props: ["attributes"],
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  mounted() {
  },
  methods: {
    /*
     * Updates the order in regards with the provided attributes
     */
    update() {
      this.$http.put(`${this.apiPath}/order`, this.attributes).then(response => {
        miniCart.loadOrder()

        this.$store.dispatch('triggerEvent', { type: 'gy::checkout-order-updated', message: response.body.order })
      })
    }
  }
})
</script>
